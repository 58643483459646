import React, {useState, useEffect} from 'react'
import './ExternalDeclaration.css'
import {
  Button,
  TextField, 
  Grid, 
  Container, 
  Checkbox,
  Select,
  MenuItem, FormControl, FormControlLabel, FormHelperText} from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import InputLabel from '@mui/material/InputLabel';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';


const ExternalDeclaration = () => {

  const [searchParams] = useSearchParams();
  const [ waitingDeclaration, setWaitingDeclaration ] = useState([]);
  const [produto, setProduto] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const baseURLListDeclaration = process.env.REACT_APP_BASEURL;
  const auth = {
    headers: {
      "Access-Control-Allow-Origin": "*",
    } 
  }

  let token = encodeURIComponent(searchParams.get('s'))

  useEffect(() => {
      
    fetchWaitingDeclaration()
    
      return () => {
        console.log("cleanup")
      }

  }, [])

  const fetchWaitingDeclaration = async () => {
      
    try {
        const response = await axios.get(baseURLListDeclaration+token, auth) 
        setWaitingDeclaration(response.data) 
        setError(response.status)

      } catch(error) {

        setError(error.reponse?.status)
        // console.log("error", error.response?.status)
    }
    setLoading(true)

  }

  //tratamento de objeto para array no waitingDeclaration
   const valueWaiting = Object.values(waitingDeclaration || "") ;

   const material = valueWaiting[6] || "";


   const putWaitingDeclaration = async () => {

    try {

      const response = await axios.put(baseURLListDeclaration+token, waitingDeclaration, auth);
      alert("Declaração Atualizada!")
    } catch (error) {

    }
}

const postWaitingDeclaration = async () => {

  try {

    const response = await axios.post(baseURLListDeclaration+token, waitingDeclaration, auth).then(response => console.log("response", response.data))
    alert("Declaração Emitida Pronta Para Assinatura!")
  } catch (error) {

  }
}

// useEffect(() => {
//   putWaitingDeclaration()
// }, [])

// useEffect(() => {
//   postWaitingDeclaration()
// },[])

      useEffect(() => {
          setProduto(waitingDeclaration?.["material-transportado"])
      }, [waitingDeclaration, produto])

      //adiciona id aos produtos quando der certo
      if(produto?.length > 0){
        produto.forEach((item, i) => {
           item.id = i
        })
      }

  const changeHandlerWaiting = (event) => {

    const { name, value } = event.target;
    // console.log("Waiting name value", name, value);
     let finalWord = name.split("-");

     if(name.includes("MaterialTransportado")) {
              
      setWaitingDeclaration((prevWaiting) => {
        const newWaiting = {...prevWaiting}
        newWaiting["tipo_material_transportado"] = value
        return newWaiting  
      })
    } 

    if(name.includes("observacao")) {
              
      setWaitingDeclaration((prevWaiting) => {
        const newWaiting = {...prevWaiting}
        newWaiting["observacao"] = value
        return newWaiting  
      })
    } 

    if(name.includes("bensInstalacao")) {
              
      setWaitingDeclaration((prevWaiting) => {
        const newWaiting = {...prevWaiting}
        newWaiting["bens-para-instalacao"] = value
        return newWaiting  
      })
    } 


    if(name.includes("MaterialTransportado")) {
              
      setWaitingDeclaration((prevWaiting) => {
        const newWaiting = {...prevWaiting}
        newWaiting["tipo_material_transportado"] = value
        return newWaiting  
      })
    } 


     if(name.includes("emails")) {

        setWaitingDeclaration((prevWaiting) => {
          const newWaiting = {...prevWaiting}
          newWaiting["emails"] = value
          return newWaiting
          })
     }

     if(name.includes("projeto")) {

      setWaitingDeclaration((prevWaiting) => {
        const newWaiting = {...prevWaiting}
        newWaiting["projeto"] = value
        return newWaiting
        })
     }

     if(name.includes("cd_responsavel")) {
        
      setWaitingDeclaration((prevWaiting) => {
        const newWaiting = {...prevWaiting}
        newWaiting["cd_responsavel"] = value
        return newWaiting
        })

     }

      if(name.includes("remetente")) {
        // console.log("Remetente", name, finalWord[finalWord.length - 1])
        
        if(name.includes("razaosocial")){
          // console.log("name remetente", name, value)

          setWaitingDeclaration((prevWaiting) => {
              const newWaiting = {...prevWaiting}
              newWaiting.remetente["razao-social"] = value
              return newWaiting
          })

        } else 
              if(name.includes("amostra")){
                // console.log("name remetente", name, value)

                if(value === "false") {
                    setWaitingDeclaration((prevWaiting) => {
                      const newWaiting = {...prevWaiting}
                      newWaiting.remetente["amostra"] = true
                      return newWaiting
                  })
                 } else {
                    setWaitingDeclaration((prevWaiting) => {
                      const newWaiting = {...prevWaiting}
                      newWaiting.remetente["amostra"] = false
                      return newWaiting
                  })
                }
          }  else 
               if(name.includes("eaf")) {
                // console.log("eaf", name, value)

                if(value === "false" || value === false) {
                  setWaitingDeclaration((prevWaiting) => {
                    const newWaiting = {...prevWaiting}
                    newWaiting.remetente["eaf"] = true
                    return newWaiting
                })
               } else {
                  setWaitingDeclaration((prevWaiting) => {
                    const newWaiting = {...prevWaiting}
                    newWaiting.remetente["eaf"] = false
                    return newWaiting
                })
              }

               } else 
               if(name.includes("cnpj") || name.includes("endereco")
                || name.includes("numero") || name.includes("complemento")   
                || name.includes("bairro") || name.includes("cidade") 
                || name.includes("uf") || name.includes("cep") 
                || name.includes("email") || name.includes("ddd")
                || name.includes("telefone") || name.includes("instaladora")) 
           {
             setWaitingDeclaration((prevWaiting) => {
               const newWaiting = {...prevWaiting}
               newWaiting.remetente[finalWord[finalWord.length-1]] = value
               return newWaiting
             })
           }
      }

      if(name.includes("destinatario")) {
        // console.log("Destinatario", name)

        if(name.includes("amostra")){
          // console.log("AMOSTRA", name, value)

          if(value === "false") {
              setWaitingDeclaration((prevWaiting) => {
                const newWaiting = {...prevWaiting}
                newWaiting.destinatario["amostra"] = true
                return newWaiting
            })
           } else {
              setWaitingDeclaration((prevWaiting) => {
                const newWaiting = {...prevWaiting}
                newWaiting.destinatario["amostra"] = false
                return newWaiting
            })
          }
    }  else 
         if(name.includes("eaf")) {

          if(value === "false") {
            setWaitingDeclaration((prevWaiting) => {
              const newWaiting = {...prevWaiting}
              newWaiting.destinatario["eaf"] = true
              return newWaiting
          })
         } else {
            setWaitingDeclaration((prevWaiting) => {
              const newWaiting = {...prevWaiting}
              newWaiting.destinatario["eaf"] = false
              return newWaiting
          })
        }

    } else 
        if(name.includes("razaosocial")){
          // console.log("name remetente", name, value)

          setWaitingDeclaration((prevWaiting) => {
              const newWaiting = {...prevWaiting}
              newWaiting.destinatario["razao-social"] = value
              return newWaiting

          })

        } else 
              if(name.includes("cnpj") || name.includes("endereco")
               || name.includes("numero") || name.includes("complemento")   
               || name.includes("bairro") || name.includes("cidade") 
               || name.includes("uf") || name.includes("cep") 
               || name.includes("email") || name.includes("ddd")
               || name.includes("telefone") || name.includes("instaladora")) 
          {
            setWaitingDeclaration((prevWaiting) => {
              const newWaiting = {...prevWaiting}
              newWaiting.destinatario[finalWord[finalWord.length-1]] = value
              return newWaiting
            })
          }

      } //end

      if(name.includes("produto")) {
        // console.log("Produto", name, value)

         if(name.includes("Descricao")) {

          setProduto((prevProduto) => {
            const newProduto = {...prevProduto}
            newProduto[name.slice(-1)].descricao = value
            return newProduto  
          })

         } else


        if(name.includes("Quantidade")) {
          //  console.log("Quantidade", name, value)   
           
          setProduto((prevProduto) => {
            const newProduto = {...prevProduto}
            newProduto[name.slice(-1)].quantidade = value
            return newProduto  
          })

      } else

        if(name.includes("ValorUnitario")) {
            
          setProduto((prevProduto) => {
            const newProduto = {...prevProduto}
            newProduto[name.slice(-1)]["valor-unitario"] = value
            return newProduto  
          })

      } else

        if(name.includes("Peso")) {
              
          setProduto((prevProduto) => {
            const newProduto = {...prevProduto}
            newProduto[name.slice(-1)].peso = value
            return newProduto  
          })
      } 
    } // end 

    
    if(name.includes("localColeta")) {
            
        if(name.includes("RazaoSocial")){
          setWaitingDeclaration((prevWaiting) => {
            const newWaiting = {...prevWaiting}
            newWaiting["local-coleta"]["razao-social"] = value
            return newWaiting  
          })
        } else 
              if(name.includes("cnpj") || name.includes("endereco")
              || name.includes("numero") || name.includes("complemento")   
              || name.includes("bairro") || name.includes("cidade") 
              || name.includes("uf") || name.includes("cep") 
              || name.includes("email") || name.includes("ddd")
              || name.includes("telefone") || name.includes("instaladora")) 
        {
          setWaitingDeclaration((prevWaiting) => {
            const newWaiting = {...prevWaiting}
            newWaiting["local-coleta"][finalWord[finalWord.length-1]] = value
            return newWaiting
          })
        }
  }

  if(name.includes("transporte")) {
     
      if(name.includes("Nome")) {

        setWaitingDeclaration((prevWaiting) => {
          const newWaiting = {...prevWaiting}
          newWaiting["transporte-caracteristicas"]["transportadora-nome"] = value
          return newWaiting  
        })
      } 

      if(name.includes("Placa")) {

        setWaitingDeclaration((prevWaiting) => {
          const newWaiting = {...prevWaiting}
          newWaiting["transporte-caracteristicas"]["veiculo-placa"] = value
          return newWaiting  
        })
      } 

      if(name.includes("Cnpj")) {

        setWaitingDeclaration((prevWaiting) => {
          const newWaiting = {...prevWaiting}
          newWaiting["transporte-caracteristicas"]["transportadora-cnpj"] = value
          return newWaiting  
        })
      } 

      if(name.includes("Volume")) {

        setWaitingDeclaration((prevWaiting) => {
          const newWaiting = {...prevWaiting}
          newWaiting["transporte-caracteristicas"]["volume"] = value
          return newWaiting  
        })
      } 

  }

} // end

console.log("Waiting Declaration", waitingDeclaration )


  return (

    <>
    { token === "null" ? (<h2 style={{ color: 'black'}}>Página não encontrada</h2>) : 
    
  
    <Container direction="row" maxWidth="xl" className="formdeclaration" 
    sx={{ width: '80%', backgroundColor: '#fff', borderRadius: '5px'}}>
         
      <div style={{ paddingLeft: '30px'}}>
          <Grid>
            <form>
              <FormControl sx={{ p: 2}}>
              <FormLabel id="cd-responsavel"> <b>CD RESPONSÁVEL*:</b> </FormLabel>
              <br />
              <RadioGroup
                row
                aria-labelledby="cd-responsavel"
                
                value={waitingDeclaration?.["cd_responsavel"] || ''}
                name={"cd_responsavel"}
                sx={{ color: '#fff', background: '#000072', p: 2, borderRadius: '25px'}}
                onChange={changeHandlerWaiting}
              >
                <FormControlLabel size='medium' value="SP" control={<Radio />} label="SP - São Paulo" />
                <FormControlLabel value="AM" control={<Radio />} label="AM - Manaus" />
                <FormControlLabel value="PE" control={<Radio />} label="PE - Recife" />
              </RadioGroup>
            </FormControl>

            <h3>1. DADOS DO REMETENTE</h3>

            <div style={{ display: 'inline-flex', alignItems: 'center', alignSelf: 'center', height: '60px'}}>
          <Checkbox 
              sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
               checked={waitingDeclaration?.remetente?.amostra || false}
               value={waitingDeclaration?.remetente?.amostra}
               name={'remetente-amostra'} 
               onChange={changeHandlerWaiting}
               label="Amostra" /> <p style={{ fontSize: '20px'}}>Amostra</p>
          
          <Checkbox 
              sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
               checked={waitingDeclaration?.remetente?.eaf || false}
              //  value={waitingDeclaration?.remetente?.eaf}
               name={'remetente-eaf'} 
               InputLabelProps={{ shrink: true }}
               onChange={changeHandlerWaiting}
               label="eaf" /> <p style={{ fontSize: '20px'}}>EAF</p>
      
          </div>

          <FormControl sx={{ width: '200px', m: 1, mb: 2, ml: 4}}> 
            <InputLabel id="demo-simple-select-label">Projeto</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={waitingDeclaration?.projeto || ''}
              name={'projeto'}
              label="Projeto"
              InputLabelProps={{ shrink: true }}
              onChange={changeHandlerWaiting}
            >
              <MenuItem value={"TVRO"}>TVRO</MenuItem>
              <MenuItem value={"FSS"}>FSS</MenuItem>
            </Select>
          </FormControl>
          <br />

          <TextField
            autoComplete='off'
            // required={true}
            InputLabelProps={{ shrink: true }}
            id="outlined-required"
            label="Razão Social"
            value={waitingDeclaration?.remetente?.["razao-social"]}
            name={`remetente-razaosocial`}
            style={{ minWidth: "50%", marginRight: '20px' }}
            margin="normal"
            onChange={e => {changeHandlerWaiting(e);}} 
          />  

          <TextField
              autoComplete='off'
              // required={true}
              type="number"
              id="outlined-required"
              helperText=""
              InputLabelProps={{ shrink: true }}
              label="CNPJ"
              value={waitingDeclaration?.remetente?.cnpj}
              name={'remetente-cnpj'}
              style={{ minWidth: "35%" }}
              margin="normal"
              onInput={e => {
                e.target.value = Math.max(0, parseInt(e.target?.value)).toString().slice(0, 14);
              }}
              onChange={e => {changeHandlerWaiting(e)}} 
            />

          <TextField
            autoComplete='off'
            // required={true}
            id="outlined-required"
            InputLabelProps={{ shrink: true }}
            label="Endereço"
            value={waitingDeclaration?.remetente?.endereco}
            name={'remetente-endereco'}
            style={{ minWidth: "50%", marginRight: '20px' }}
            margin="normal"
            onChange={e => {changeHandlerWaiting(e)}} 
          />

          <TextField
            required={true}
            type={"text"}
            id="outlined-required"
            InputLabelProps={{ shrink: true }}
            label="Número"
            value={waitingDeclaration?.remetente?.numero}
            name={'remetente-numero'}
            margin="normal"
            style={{ minWidth: '10%', marginRight: '20px'}}
            onInput={e => {
              e.target.value = e.target?.value.toString().slice(0, 8);
            }}
            onChange={e => changeHandlerWaiting(e)}
          />

          <TextField
            autoComplete='off'
            // required={true}
            id="outlined-required"
            InputLabelProps={{ shrink: true }}
            label="Complemento"
            value={waitingDeclaration?.remetente?.complemento || ''}
            name={'remetente-complemento'}
            style={{ minWidth: "20%" }}
            margin="normal"
            onChange={e => {changeHandlerWaiting(e)}} 
          />

          <TextField
            required={true}
            type={"text"}
            id="outlined-required"
            label="Bairro"
            value={waitingDeclaration?.remetente?.bairro}
            name={'remetente-bairro'}
            margin="normal"
            style={{ marginRight: '20px'}}
             InputLabelProps={{ shrink: true }}
            onChange={e => changeHandlerWaiting(e)}
          />

          <TextField
            required={true}
            type={"text"}
            id="outlined-required"
            label="Cidade"
            value={waitingDeclaration?.remetente?.cidade}
            InputLabelProps={{ shrink: true }}
            name={'remetente-cidade'}
            margin="normal"
            onChange={e => changeHandlerWaiting(e)}
          />

              <FormControl>
                  <FormHelperText>UF
                  <Select 
                      sx={{
                        mt: 1,
                        width: 100,
                        height: 50,
                      }}
                      label="UF"
                       InputLabelProps={{ shrink: true }}
                      defaultValue={waitingDeclaration?.remetente?.uf || ''}
                      value={waitingDeclaration?.remetente?.uf || ''}
                      name={'remetente-uf'}
                      margin="normal"
                      onChange={e => {changeHandlerWaiting(e)}}
                    >
                      <MenuItem value="AM">AM</MenuItem>
                      <MenuItem value="SP">SP</MenuItem>
                      <MenuItem value="PE">PE</MenuItem>
                      <MenuItem value="RJ">RJ</MenuItem>
                      <MenuItem value="BA">BA</MenuItem>
                      <MenuItem value="AC">AC</MenuItem>
                      <MenuItem value="AL">AL</MenuItem>
                      <MenuItem value="AP">AP</MenuItem>
                      <MenuItem value="BA">BA</MenuItem>
                      <MenuItem value="CE">CE</MenuItem>
                      <MenuItem value="ES">ES</MenuItem>
                      <MenuItem value="GO">GO</MenuItem>
                      <MenuItem value="MA">MA</MenuItem>
                      <MenuItem value="MT">MT</MenuItem>
                      <MenuItem value="MS">MS</MenuItem>
                      <MenuItem value="MG">MG</MenuItem>
                      <MenuItem value="PA">PA</MenuItem>
                      <MenuItem value="PB">PB</MenuItem>
                      <MenuItem value="PR">PR</MenuItem>
                      <MenuItem value="PI">PI</MenuItem>
                      <MenuItem value="RO">RO</MenuItem>
                      <MenuItem value="RN">RN</MenuItem>
                      <MenuItem value="RR">RR</MenuItem>
                      <MenuItem value="SE">SE</MenuItem>
                      <MenuItem value="SC">SC</MenuItem>
                      <MenuItem value="TO">TO</MenuItem>
                      <MenuItem value="DF">DF</MenuItem>
                    </Select>
                    </FormHelperText>
                    </FormControl>

          <TextField
            required={true}
            type={"text"}
            id="outlined-required"
            label="CEP"
            value={waitingDeclaration?.remetente?.cep}
            name={'remetente-cep'}
            InputLabelProps={{ shrink: true }}
            margin="normal"
            style={{ marginRight: '20px'}}
             onInput={e => {
              e.target.value = e.target?.value.toString().slice(0, 8);
            }}
            onChange={e => changeHandlerWaiting(e)}
          />

          <TextField
            required={true}
            type={"text"}
            id="outlined-required"
            label="Email"
            value={waitingDeclaration?.remetente?.email}
            name={'remetente-email'}
            margin="normal"
            InputLabelProps={{ shrink: true }}
            onChange={e => changeHandlerWaiting(e)}
          />

        <TextField
            required={true}
            type={"text"}
            id="outlined-required"
            label="DDD"
            value={waitingDeclaration?.remetente?.ddd}
            name={'remetente-ddd'}
            margin="normal"
            style={{ marginRight: '20px'}}
            InputLabelProps={{ shrink: true }}
             onInput={e => {
              e.target.value = e.target?.value.toString().slice(0, 8);
            }}
            onChange={e => changeHandlerWaiting(e)}
          />

          <TextField
            required={true}
            type={"text"}
            id="outlined-required"
            label="telefone"
            value={waitingDeclaration?.remetente?.telefone}
            name={'remetente-telefone'}
            margin="normal"
            style={{ marginRight: '20px'}}
            InputLabelProps={{ shrink: true }}
             onInput={e => {
              e.target.value = e.target?.value.toString().slice(0, 9);
            }}
            onChange={e => changeHandlerWaiting(e)}
          />

          <TextField
            required={true}
            type={"text"}
            id="outlined-required"
            label="Instaladora"
            value={waitingDeclaration?.remetente?.instaladora}
            name={'remetente-instaladora'}
            margin="normal"
            InputLabelProps={{ shrink: true }}
            onChange={e => changeHandlerWaiting(e)}
          />      

<h3>2. DADOS DO DESTINÁTARIO</h3>

        <div style={{display: 'flex', marginBottom: '20px'}}>      
          <Checkbox 
              sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
               checked={waitingDeclaration?.destinatario?.amostra || false}
               value={waitingDeclaration?.destinatario?.amostra}
               name={'destinatario-amostra'} 
               onChange={changeHandlerWaiting}
               label="Amostra" /> <p style={{ fontSize: '20px'}}>Amostra</p>
          
          <Checkbox 
              sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
               checked={waitingDeclaration?.destinatario?.eaf || false}
               value={waitingDeclaration?.destinatario?.eaf}
               name={'destinatario-eaf'} 
               onChange={changeHandlerWaiting}
               label="eaf" /> <p style={{ fontSize: '20px'}}>EAF</p>

          </div>
          

<TextField
  autoComplete='off'
  // required={true}
  id="outlined-required"
  label="Razão Social"
  value={waitingDeclaration?.destinatario?.["razao-social"]}
  name={'destinatario-razaosocial'}
  InputLabelProps={{ shrink: true }}
  style={{ minWidth: "50%", marginRight: '20px' }}
  margin="normal"
  onChange={e => {changeHandlerWaiting(e);}} 
/>  

<TextField
    autoComplete='off'
    // required={true}
    type="number"
    id="outlined-required"
    helperText=""
    label="CNPJ"
    value={waitingDeclaration?.destinatario?.cnpj}
    name={'destinatario-cnpj'}
    style={{ minWidth: "35%" }}
    InputLabelProps={{ shrink: true }}
    margin="normal"
    onInput={e => {
      e.target.value = Math.max(0, parseInt(e.target?.value)).toString().slice(0, 14);
    }}
    onChange={e => {changeHandlerWaiting(e)}} 
  />

<TextField
  autoComplete='off'
  // required={true}
  id="outlined-required"
  label="Endereço"
  value={waitingDeclaration?.destinatario?.endereco}
  InputLabelProps={{ shrink: true }}
  name={'destinatario-endereco'}
  style={{ minWidth: "50%", marginRight: '20px' }}
  margin="normal"
  onChange={e => {changeHandlerWaiting(e)}} 
/>

<TextField
  required={true}
  type={"text"}
  id="outlined-required"
  label="Número"
  value={waitingDeclaration?.destinatario?.numero}
  name={'destinatario-numero'}
  margin="normal"
  style={{ marginRight: '20px'}}
  InputLabelProps={{ shrink: true }}
  onInput={e => {
    e.target.value = e.target?.value.toString().slice(0, 8);
  }}
  onChange={e => changeHandlerWaiting(e)}
/>

<TextField
  autoComplete='off'
  // required={true}
  id="outlined-required"
  label="Complemento"
  value={waitingDeclaration?.destinatario?.complemento || ''}
  InputLabelProps={{ shrink: true }}
  name={'destinatario-complemento'}
  margin="normal"
  style={{ minWidth: "20%" }}
  onChange={e => {changeHandlerWaiting(e)}} 
/>

<TextField
  required={true}
  type={"text"}
  id="outlined-required"
  label="Bairro"
   InputLabelProps={{ shrink: true }}
  value={waitingDeclaration?.destinatario?.bairro}
  name={'destinatario-bairro'}
  margin="normal"
  style={{ marginRight: '20px'}}
  onChange={e => changeHandlerWaiting(e)}
/>

<TextField
  required={true}
  type={"text"}
  id="outlined-required"
  label="Cidade"
  value={waitingDeclaration?.destinatario?.cidade}
  name={'destinatario-cidade'}
  margin="normal"
  style={{ marginRight: '20px'}}
  InputLabelProps={{ shrink: true }}
  onChange={e => changeHandlerWaiting(e)}
/>

    <FormControl>
        <FormHelperText>UF
        <Select 
            sx={{
              mt: 1,
              width: 100,
              height: 50,
            }}
            label="UF"
            InputLabelProps={{ shrink: true }}
            defaultValue={waitingDeclaration?.destinatario?.uf || ''}
            value={waitingDeclaration?.destinatario?.uf || ''}
            name={'destinatario-uf'}
            margin="normal"
            style={{ marginRight: '20px'}}
            onChange={e => {changeHandlerWaiting(e)}}
          >
            <MenuItem value="AM">AM</MenuItem>
                      <MenuItem value="SP">SP</MenuItem>
                      <MenuItem value="PE">PE</MenuItem>
                      <MenuItem value="RJ">RJ</MenuItem>
                      <MenuItem value="BA">BA</MenuItem>
                      <MenuItem value="AC">AC</MenuItem>
                      <MenuItem value="AL">AL</MenuItem>
                      <MenuItem value="AP">AP</MenuItem>
                      <MenuItem value="BA">BA</MenuItem>
                      <MenuItem value="CE">CE</MenuItem>
                      <MenuItem value="ES">ES</MenuItem>
                      <MenuItem value="GO">GO</MenuItem>
                      <MenuItem value="MA">MA</MenuItem>
                      <MenuItem value="MT">MT</MenuItem>
                      <MenuItem value="MS">MS</MenuItem>
                      <MenuItem value="MG">MG</MenuItem>
                      <MenuItem value="PA">PA</MenuItem>
                      <MenuItem value="PB">PB</MenuItem>
                      <MenuItem value="PR">PR</MenuItem>
                      <MenuItem value="PI">PI</MenuItem>
                      <MenuItem value="RO">RO</MenuItem>
                      <MenuItem value="RN">RN</MenuItem>
                      <MenuItem value="RR">RR</MenuItem>
                      <MenuItem value="SE">SE</MenuItem>
                      <MenuItem value="SC">SC</MenuItem>
                      <MenuItem value="TO">TO</MenuItem>
                      <MenuItem value="DF">DF</MenuItem>
          </Select>
          </FormHelperText>
          </FormControl>

<TextField
  required={true}
  type={"text"}
  id="outlined-required"
  label="CEP"
  value={waitingDeclaration?.destinatario?.cep}
  name={'destinatario-cep'}
  margin="normal"
  style={{ marginRight: '20px'}}
  InputLabelProps={{ shrink: true }}
  onInput={e => {
    e.target.value = e.target?.value.toString().slice(0, 8);
  }}
  onChange={e => changeHandlerWaiting(e)}
/>

<TextField
  required={true}
  type={"text"}
  id="outlined-required"
  label="Email"
  value={waitingDeclaration?.destinatario?.email}
  name={'destinatario-email'}
  margin="normal"
  InputLabelProps={{ shrink: true }}
  onChange={e => changeHandlerWaiting(e)}
/>

<TextField
  required={true}
  type={"text"}
  id="outlined-required"
  label="DDD"
  value={waitingDeclaration?.destinatario?.ddd}
  name={'destinatario-ddd'}
  margin="normal"
  style={{ marginRight: '20px'}}
  InputLabelProps={{ shrink: true }}
  onInput={e => {
    e.target.value = e.target?.value.toString().slice(0, 8);
  }}
  onChange={e => changeHandlerWaiting(e)}
/>

<TextField
  required={true}
  type={"text"}
  id="outlined-required"
  label="telefone"
  value={waitingDeclaration?.destinatario?.telefone}
  name={'destinatario-telefone'}
  margin="normal"
  style={{ marginRight: '20px'}}
  InputLabelProps={{ shrink: true }}
  onInput={e => {
    e.target.value = e.target?.value.toString().slice(0, 12);
  }}
  onChange={e => changeHandlerWaiting(e)}
/>

<TextField
  required={true}
  type={"text"}
  id="outlined-required"
  label="Instaladora"
  value={waitingDeclaration?.destinatario?.instaladora}
  name={'destinatario-instaladora'}
  margin="normal"
  InputLabelProps={{ shrink: true }}
  onChange={e => changeHandlerWaiting(e)}
/>      

<h3>3. MATERIAL TRANSPORTADO</h3>


                { material?.length > 0 && material.map((produto, index) => {
                    return (
                      <div key={index}>
            
                        <TextField
                         label="Descrição"
                         value={produto.descricao}
                         name={'produtoDescricao'+ `${index}`}
                         style={{ minWidth: "30%", marginRight: '20px' }}
                         margin="normal"
                         onChange={changeHandlerWaiting}
                       />

                      <TextField
                         id={`${index}`}
                         label="Quantidade"
                         value={produto.quantidade}
                         name={'produtoQuantidade'+ `${index}`}
                         style={{ minWidth: "10%", marginRight: '20px' }}
                         margin="normal"
                         onChange={changeHandlerWaiting}
                       />

                      <TextField
                         id={`${index}`}
                         label="Valor Unitário"
                         value={produto["valor-unitario"]}
                         name={'produtoValorUnitario'+ `${index}`}
                         style={{ minWidth: "10%", marginRight: '20px' }}
                         margin="normal"
                         onChange={changeHandlerWaiting}
                       />

                      <TextField
                         id={`${index}`}
                         label="Peso"
                         value={produto.peso}
                         name={'produtoPeso'+ `${index}`}
                         style={{ minWidth: "10%", marginRight: '20px' }}
                         margin="normal"
                         onChange={changeHandlerWaiting}
                       />

                      
                      </div>
                    )  
                })
            }

<TextField 
                         label="Tipo Material Transportado"
                         value={waitingDeclaration["tipo_material_transportado"]}
                         name={'MaterialTransportado'}
                         style={{ minWidth: "30%" }}
                         InputLabelProps={{ shrink: true }}
                         margin="normal"
                         onChange={changeHandlerWaiting}
                       />




<h3>4. CARACTERÍSTICAS DO TRANSPORTE</h3>

                      <TextField
                         label="Nome da Transportadora"
                         value={waitingDeclaration?.["transporte-caracteristicas"]?.["transportadora-nome"]}
                         name={'transporteNome'}
                         style={{ minWidth: "50%", marginRight: '20px' }}
                         margin="normal"
                         InputLabelProps={{ shrink: true }}
                         onChange={changeHandlerWaiting}
                       />

                      <TextField
                         label="Placa do Veículo"
                         value={waitingDeclaration?.["transporte-caracteristicas"]?.["veiculo-placa"]}
                         name={'transportePlaca'}
                         style={{ minWidth: "10%", marginRight: '20px' }}
                         margin="normal"
                         InputLabelProps={{ shrink: true }}
                         onChange={changeHandlerWaiting}
                       />

                      <TextField
                         label="CNPJ"
                         value={waitingDeclaration?.["transporte-caracteristicas"]?.["transportadora-cnpj"]}
                         name={'transporteCnpj'}
                         style={{ minWidth: "20%", marginRight: '20px' }}
                         margin="normal"
                         InputLabelProps={{ shrink: true }}
                         onChange={changeHandlerWaiting}
                       />

                      <TextField
                         label="Volume"
                         value={waitingDeclaration?.["transporte-caracteristicas"]?.["volume"]}
                         name={'transporteVolume'}
                         style={{ minWidth: "30%" }}
                         margin="normal"
                         InputLabelProps={{ shrink: true }}
                         onChange={changeHandlerWaiting}
                       />

                      <div style={{ display: 'flex'}}>

                        <Checkbox 
                          sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                          checked={waitingDeclaration?.["transporte-caracteristicas"]?.["icms"] || false}
                          value={waitingDeclaration?.["transporte-caracteristicas"]?.["icms"]}
                          name={'transporteICMS'} 
                          margin="normal"
                          onChange={changeHandlerWaiting}
                          label="ICMS" /> <p style={{ fontSize: '20px'}}>O transporte não será efetuado por contribuinte do ICMS - Nesse caso, os dados acima serão dispensados</p>

                      </div>

<h3>5. NATUREZA DA OPERÇÃO REALIZADA</h3>

                      <div style={{ display: 'flex'}}>

                        <Checkbox 
                          sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                          checked={waitingDeclaration?.["bens-para-instalacao"] || false}
                          value={waitingDeclaration?.["bens-para-instalacao"]}
                          name={'bensInstalacao'} 
                          margin="normal"
                          onChange={changeHandlerWaiting}
                          label="Bens para instalacao" /> <p style={{ fontSize: '20px'}}>O Transporte de Bens para Instalação</p>

                      </div>
                     
                      <h4>Observações:</h4>

                      <TextField
                          id="outlined-multiline-static"
                          label="Observações"
                          multiline
                          style ={{ width: "100%"}}
                          margin="normal"
                          InputLabelProps={{ shrink: true }}
                          rows={4} 
                          name={'observacao'}
                          value={waitingDeclaration?.observacao}
                          onChange={changeHandlerWaiting}      
                        />

                      <h4>Local da Entrega:</h4>

                      <TextField
                        autoComplete='off'
                        // required={true}
                        id="outlined-required"
                        label="Razão Social"
                        value={waitingDeclaration?.["local-coleta"]?.["razao-social"]}
                        InputLabelProps={{ shrink: true }}
                        name={'localColetaRazaoSocial'}
                        style={{ minWidth: "50%", marginRight: '20px' }}
                        margin="normal"
                        onChange={e => {changeHandlerWaiting(e);}} 
                      />  

                      <TextField
                          autoComplete='off'
                          // required={true}
                          type="number"
                          InputLabelProps={{ shrink: true }}
                          id="outlined-required"
                          helperText=""
                          label="CNPJ"
                          value={waitingDeclaration?.["local-coleta"]?.cnpj}
                          name={'localColeta-cnpj'}
                          style={{ minWidth: "35%" }}
                          margin="normal"
                          onInput={e => {
                            e.target.value = Math.max(0, parseInt(e.target?.value)).toString().slice(0, 14);
                          }}
                          onChange={e => {changeHandlerWaiting(e)}} 
                        />

                      <TextField
                        autoComplete='off'
                        InputLabelProps={{ shrink: true }}
                        // required={true}
                        id="outlined-required"
                        label="Endereço"
                        value={waitingDeclaration?.["local-coleta"]?.endereco}
                        name={'localColeta-endereco'}
                        style={{ minWidth: "50%", marginRight: '20px' }}
                        margin="normal"
                        onChange={e => {changeHandlerWaiting(e)}} 
                      />

                      <TextField
                        required={true}
                        InputLabelProps={{ shrink: true }}
                        type={"text"}
                        id="outlined-required"
                        label="Número"
                        value={waitingDeclaration?.["local-coleta"]?.numero}
                        name={'localColeta-numero'}
                        margin="normal"
                        style={{ minWidth: "10%", marginRight: '20px' }}
                        onInput={e => {
                          e.target.value = e.target?.value.toString().slice(0, 8);
                        }}
                        onChange={e => changeHandlerWaiting(e)}
                      />

                      <TextField
                        type={"text"}
                        InputLabelProps={{ shrink: true }}
                        autoComplete='off'
                        // required={true}
                        id="outlined-required"
                        label="Complemento"
                        value={waitingDeclaration?.["local-coleta"]?.complemento || ''}
                        name={'localColeta-complemento'}
                        margin="normal"
                        style={{ minWidth: "20%" }}
                        onChange={e => {changeHandlerWaiting(e)}} 
                      />

                      <TextField
                        required={true}
                        type={"text"}
                        id="outlined-required"
                        label="Bairro"
                        value={waitingDeclaration?.["local-coleta"]?.bairro}
                        name={'localColeta-bairro'}
                        margin="normal"
                        style={{ minWidth: "10%", marginRight: '20px' }}
                        InputLabelProps={{ shrink: true }}
                        onChange={e => changeHandlerWaiting(e)}
                      />

                      <TextField
                        required={true}
                        type={"text"}
                        id="outlined-required"
                        label="Cidade"
                        value={waitingDeclaration?.["local-coleta"]?.cidade}
                        name={'localColeta-cidade'}
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        onChange={e => changeHandlerWaiting(e)}
                      />

                          <FormControl>
                              <FormHelperText>UF
                              <Select 
                                  sx={{
                                    mt: 1,
                                    width: 100,
                                    height: 50,
                                  }}
                                  label="UF"
                                  InputLabelProps={{ shrink: true }}
                                  defaultValue={waitingDeclaration?.["local-coleta"]?.uf || ''}
                                  value={waitingDeclaration?.["local-coleta"]?.uf || ''}
                                  name={'localColeta-uf'}
                                  margin="normal"
                                  onChange={e => {changeHandlerWaiting(e)}}
                                >
                                  <MenuItem value="AM">AM</MenuItem>
                                  <MenuItem value="SP">SP</MenuItem>
                                  <MenuItem value="PE">PE</MenuItem>
                                  <MenuItem value="RJ">RJ</MenuItem>
                                  <MenuItem value="BA">BA</MenuItem>
                                  <MenuItem value="AC">AC</MenuItem>
                                  <MenuItem value="AL">AL</MenuItem>
                                  <MenuItem value="AP">AP</MenuItem>
                                  <MenuItem value="BA">BA</MenuItem>
                                  <MenuItem value="CE">CE</MenuItem>
                                  <MenuItem value="ES">ES</MenuItem>
                                  <MenuItem value="GO">GO</MenuItem>
                                  <MenuItem value="MA">MA</MenuItem>
                                  <MenuItem value="MT">MT</MenuItem>
                                  <MenuItem value="MS">MS</MenuItem>
                                  <MenuItem value="MG">MG</MenuItem>
                                  <MenuItem value="PA">PA</MenuItem>
                                  <MenuItem value="PB">PB</MenuItem>
                                  <MenuItem value="PR">PR</MenuItem>
                                  <MenuItem value="PI">PI</MenuItem>
                                  <MenuItem value="RO">RO</MenuItem>
                                  <MenuItem value="RN">RN</MenuItem>
                                  <MenuItem value="RR">RR</MenuItem>
                                  <MenuItem value="SE">SE</MenuItem>
                                  <MenuItem value="SC">SC</MenuItem>
                                  <MenuItem value="TO">TO</MenuItem>
                                  <MenuItem value="DF">DF</MenuItem>
                                </Select>
                                </FormHelperText>
                                </FormControl>

                      <TextField
                        required={true}
                        type={"text"}
                        id="outlined-required"
                        label="CEP"
                        value={waitingDeclaration?.["local-coleta"]?.cep}
                        name={'localColeta-cep'}
                        margin="normal"
                        style={{ minWidth: "10%", marginRight: '20px' }}
                        InputLabelProps={{ shrink: true }}
                        onInput={e => {
                          e.target.value = e.target?.value.toString().slice(0, 8);
                        }}
                        onChange={e => changeHandlerWaiting(e)}
                      />

                      <TextField
                        required={true}
                        type={"text"}
                        id="outlined-required"
                        label="Email"
                        value={waitingDeclaration?.["local-coleta"]?.email}
                        name={'localColeta-email'}
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        onChange={e => changeHandlerWaiting(e)}
                      />

                      <TextField
                        required={true}
                        type={"text"}
                        id="outlined-required"
                        label="DDD"
                        value={waitingDeclaration?.["local-coleta"]?.ddd}
                        name={'localColeta-ddd'}
                        margin="normal"
                        style={{ minWidth: "10%", marginRight: '20px' }}
                        InputLabelProps={{ shrink: true }}
                        onInput={e => {
                          e.target.value = e.target?.value.toString().slice(0, 8);
                        }}
                        onChange={e => changeHandlerWaiting(e)}
                      />

                      <TextField
                        required={true}
                        type={"text"}
                        id="outlined-required"
                        label="telefone"
                        value={waitingDeclaration?.["local-coleta"]?.telefone}
                        name={'localColeta-telefone'}
                        margin="normal"
                        style={{ minWidth: "10%", marginRight: '20px' }}
                        InputLabelProps={{ shrink: true }}
                        onInput={e => {
                          e.target.value = e.target?.value.toString().slice(0, 9);
                        }}
                        onChange={e => changeHandlerWaiting(e)}
                      />

                      <TextField
                        required={true}
                        type={"text"}
                        id="outlined-required"
                        label="Instaladora"
                        value={waitingDeclaration?.["local-coleta"]?.instaladora}
                        name={'localColeta-instaladora'}
                        margin="normal"
                        style={{ minWidth: "20%" }}
                        InputLabelProps={{ shrink: true }}
                        onChange={e => changeHandlerWaiting(e)}
                      />    

                      <br /> 
                      {/* Implementar onClick botao put */}
                      {/* onClick={putWaitingDeclaration(`${waitingDeclaration.id}`)} */}
                      <Button id={`${waitingDeclaration.id}`} onClick={(e) => putWaitingDeclaration(e.target.id)}  variant="contained" sx={{ mb: 4, mt: 3, mr: 3}}>ATUALIZAR</Button>
                      <Button id={`${waitingDeclaration.id}`} onClick={(e) => postWaitingDeclaration(e.target.id)}  variant="contained" sx={{ mb: 4, mt: 3, mr: 3}}>EMITIR DECLARAÇÃO</Button>
        </form>
      </Grid>
      </div>
    </Container>
         }
    </>
  )
}

export default ExternalDeclaration