import { useSearchParams } from "react-router-dom";
import ExternalDeclaration from "./pages/Logistics/ExternalDeclaration";
import './App.css';


function App() {

const [searchParams] = useSearchParams();
let token = encodeURIComponent(searchParams.get('s'));

console.log("token", token)

  return (
    <div className="App">
      <header className="App-header">
       
        <p>
          External CD Logística
        </p>
        <br />
        <ExternalDeclaration />
        
      </header>
    </div>
  );
}

export default App;
